import { Suspense } from "react";
import { Accordion, Container } from "react-bootstrap";

const brindes = [
  {
    categoria: "TV",
    dinamica: "R$50 off em seleção de tv",
  },
  {
    categoria: "Smartphones",
    dinamica: "R$50 off em seleção de smartphones",
  },
  {
    categoria: "Notebooks",
    dinamica: "R$100 off em seleção de notebooks",
  },
  {
    categoria: "Tablets",
    dinamica: "R$50 off em seleção de tablets",
  },
  {
    categoria: "cuidados pessoais",
    dinamica: "20% off em seleção de beleza",
  },
  {
    categoria: "Impressoras",
    dinamica: "R$50 off em seleção de impressoras",
  },
  {
    categoria: "Portáteis",
    dinamica: "20% off em portateis",
  },
  {
    categoria: "Áudio",
    dinamica: "R$20 off em seleção de audio",
  },
  {
    categoria: "Mercado",
    dinamica: "R$20 off na aba mercado",
  },
  {
    categoria: "Mercado",
    dinamica: "R$50 off na aba mercado",
  },
  {
    categoria: "Chocolates Importados",
    dinamica: "Leve 2 Pague 1",
  },
  {
    categoria: "Todas as Cápsulas com 50% de desconto",
    dinamica: "50% de desconto",
  },
  {
    categoria: "Todos os Biscoitos Recheados e Waffer",
    dinamica: "Leve 4 Pague 2",
  },
  {
    categoria: "Todos os Azeites",
    dinamica: "50% de desconto",
  },
  {
    categoria: "Maionese Hellmanns SQZ",
    dinamica: "50% de desconto",
  },
];

export function Component() {
  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container>
          <h1 className="text-center mb-5">DÚVIDAS</h1>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                1. Como faço para participar da promoção "Carrinho Cheio o Ano
                Inteiro"?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Para participar da promoção o consumidor deverá efetuar
                  compras a partir de R$ 150,00 (cento e cinquenta reais) nas
                  lojas Carrefour, Carrefour Bairro, Bom Preço e Nacional, no
                  período de 02.04.2024 a 29.04.2024.
                </p>

                <p>
                  Na modalidade sorteio o consumidor deverá acessar o site{" "}
                  <a
                    href="https://www.carrinhocheiooanointeiro.com.br"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    www.carrinhocheiooanointeiro.com.br
                  </a>{" "}
                  e efetuar seu cadastramento (pessoal e de compra), preenchendo
                  todos os campos disponibilizados. Terá direito a 1 (um) número
                  da sorte, caso o consumidor seja membro do programa Meu
                  Carrefour e/ou faça o pagamento da compra utilizando o Cartão
                  CRF, o consumidor receberá o dobro de números da sorte. Na
                  modalidade compre e ganhe o consumidor deverá acessar o site{" "}
                  <a
                    href="https://www.carrinhocheiooanointeiro.com.br"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    www.carrinhocheiooanointeiro.com.br
                  </a>{" "}
                  e efetuar seu cadastramento (pessoal e de compra), preenchendo
                  todos os campos disponibilizados. A partir de R$ 150,00 (cento
                  e cinquenta reais) em compras realizadas nas lojas Carrefour,
                  Carrefour Bairro, Nacional e Bom Preço, o consumidor poderá
                  girar a roleta virtual para descobrir o brinde que faz jus,
                  caso o consumidor seja membro do Programa Meu Carrefour ou
                  faça o pagamento da compra utilizando o Cartão CRF, o
                  consumidor poderá girar a roleta 2 (duas) vezes.
                </p>

                <p>
                  Na modalidade compre e ganhe todas as participações dão
                  direito a um dos prêmios estabelecidos em regulamento, após o
                  cadastro o consumidor terá o direito de girar a roleta para
                  saber qual foi o brinde, caso o consumidor faça o pagamento da
                  compra utilizando o Cartão CRF, poderá girar a roleta 2 (duas)
                  vezes.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                2. Quem pode participar da promoção?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Podem participar desta promoção todos os consumidores, pessoas
                  físicas, com idade igual ou superior a 18 (dezoito) anos que
                  efetuarem compras a partir de R$ 150,00 (Cento e cinquenta
                  reais) nas lojas lojas Carrefour, Carrefour Bairro, Bom Preço
                  e Nacional, no período de 02.04.2024 a 29.04.2024, desde que
                  obedecidos os demais requisitos do Regulamento.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                3. Quem não pode participar da promoção?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  É vedada a participação nesta PROMOÇÃO de qualquer funcionário
                  ou membro do corpo diretivo pertencente a empresa Promotora
                  Mandatária, empresa Promotora Aderentes, Promosorte
                  Consultoria em Promoções Ltda, Comuniclick e qualquer outra
                  empresa envolvida na operacionalização da promoção e/ou na sua
                  premiação.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                4. Todos os produtos participam da promoção?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Os medicamentos, armas e munições, fogos de artifício ou de
                  estampido, bebidas alcoólicas acima de 13º Gay Lussac, fumo e
                  seus derivados, não participam desta promoção, conforme artigo
                  10 do Decreto nº 70.951/72.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                5. Qual período de participação?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  O período de participação é de de 02.04.2024 a 29.04.2024.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                6. Quantas chances eu tenho de participar na modalidade compre e
                ganhe?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  O Consumidor terá 1 (uma) chance por cupom fiscal a partir de
                  R$150,00 (cento e cinquenta Reais) em compras nas lojas
                  participantes. Caso o consumidor seja membro do Programa Meu
                  Carrefour ou faça o pagamento da compra utilizando o Cartão
                  CRF, o consumidor poderá girar a roleta 2 (duas) vezes.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                7. Fui contemplado(a) no compre e ganhe , posso concorrer também
                na modalidade sorteio?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Sim, basta seguir as regras do regulamento, acessar o site{" "}
                  <a
                    href="https://www.carrinhocheiooanointeiro.com.br"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    www.carrinhocheiooanointeiro.com.br
                  </a>{" "}
                  e efetuar seu cadastramento (pessoal e de compra), preenchendo
                  todos os campos disponibilizados.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                8. Posso ganhar mais de um número da sorte por compra para
                concorrer ao sorteio?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Sim, caso o consumidor seja membro do programa Meu Carrefour
                  e/ou faça o pagamento da compra utilizando o Cartão CRF, o
                  consumidor receberá mais 1 (um) número da sorte para concorrer
                  aos prêmios, como está descrito nos exemplos abaixo:
                </p>
                <p>
                  Exemplo 1: Compra no valor de R$ 152,70 pago com cartão CRF =
                  2 Números da Sorte. O saldo será desconsiderado;
                </p>
                <p>
                  Exemplo 2: Compra no valor de R$ 387,34 = 2 Números da Sorte.
                  O saldo será desconsiderado
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                9. Existe algum limite de compra por CPF?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  O consumidor poderá ter, em seu nome e CPF, a quantidade
                  máxima de R$ 1.000,00 (mil reais) em compras cadastradas em
                  todo o período promocional.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                10. Preciso guardar o cupom fiscal da compra?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Sim, os participantes deverão, obrigatoriamente, guardar
                  consigo todas os Cupons Fiscais cadastrados, inclusive em caso
                  de premiação.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>
                11. Posso usar o mesmo cupom fiscal mais de uma vez?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Não, cada Cupom Fiscal poderá ser utilizado somente 1 (uma)
                  única vez por um único participante.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>
                12. Onde posso visualizar meus números da sorte?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Os números da sorte poderão ser consultados pelo site da
                  promoção{" "}
                  <a
                    href="https://www.carrinhocheiooanointeiro.com.br"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    www.carrinhocheiooanointeiro.com.br
                  </a>
                  .
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>
                13. Quais serão os prêmios ofertados na promoção?
              </Accordion.Header>
              <Accordion.Body>
                <p>Na modalidade sorteio, serão ofertados:</p>
                <p>
                  - 50 (cinquenta) cartões virtuais, sem a função saque,
                  carregado com o valor de R$ 12.000,00 (doze mil reais) cada,
                  cuja sugestão de prêmio será para compras no Carrefour por 12
                  meses.
                </p>
                <p>
                  Na Modalidade compre e ganhe, onde todos são contemplados com
                  um brinde, os brindes serão:
                </p>

                <table className="table table-striped table-bordered">
                  <thead className="table-secondary">
                    <tr>
                      <th colSpan={2} className="text-center text-danger">Brindes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {brindes.map((brinde, index) => (
                      <tr key={index}>
                        <td className="text-center fw-bold">{brinde.categoria}</td>
                        <td className="text-center fw-bold">{brinde.dinamica}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header>
                14. Posso trocar o prêmio do compre e ganhe por dinheiro?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Não, os brindes são individuais e não podem ser trocados por
                  dinheiro.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header>
                15. Qual o prazo para entregar do prêmio?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  A empresa Promotora efetuará a entrega do Prêmio, em até 30
                  (trinta) dias após a Extração da Loteria Federal.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
              <Accordion.Header>
                16. Como vou saber se fui contemplado no sorteio?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Os nomes e números da sorte dos participantes ganhadores serão
                  divulgados no site{" "}
                  <a
                    href="https://www.carrinhocheiooanointeiro.com.br"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    www.carrinhocheiooanointeiro.com.br
                  </a>
                  , bem como comunicado por telefone ou e-mail, no prazo máximo
                  de 10 (dez) dias úteis, a contar da data da extração da
                  Loteria Federal e estarão disponíveis para consulta por até 20
                  dias a contar da última apuração.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="16">
              <Accordion.Header>
                17. Depois de sorteado, posso ser desclassificado?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Caso o contemplado não seja localizado ou não apresente os
                  documentos elencados acima no prazo de 3 (três) dias úteis do
                  efetivo contato das empresas Promotoras ou, ainda, os
                  apresente de forma divergente do cadastro, ele será
                  automaticamente desclassificado e o prêmio correspondente será
                  destinado a outro participante, de acordo com a regra prevista
                  em regulamento.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
              <Accordion.Header>
                18. Onde posso obter informações sobre o tratamento dos meus
                dados pessoais?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Essas informações podem ser obtidas na Política de Privacidade
                  do site. Para acessá-la, clique aqui (hiperlink na palavra
                  "aqui" direcionando para a Política).
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </section>
    </Suspense>
  );
}
